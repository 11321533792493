import { render, staticRenderFns } from "./applyCommunityTalent.vue?vue&type=template&id=2ff59d5e&scoped=true&"
import script from "./applyCommunityTalent.vue?vue&type=script&lang=js&"
export * from "./applyCommunityTalent.vue?vue&type=script&lang=js&"
import style0 from "./applyCommunityTalent.vue?vue&type=style&index=0&id=2ff59d5e&prod&lang=less&scoped=true&"
import style1 from "./applyCommunityTalent.vue?vue&type=style&index=1&id=2ff59d5e&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff59d5e",
  null
  
)

export default component.exports